import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

// import Typography from "@material-ui/core/Typography";



const theme = createMuiTheme();

const useStyles = makeStyles({
  root: {
    width: "370px",
    height: "170px",
    backgroundColor: "white",
    // opacity:"0.4",
    WebkitFilter: " opacity(0.9)",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    //marginTop: "20px",
    zIndex: "100",
    margin:"10px",
    [theme.breakpoints.down("xs")]: {
      width: "340px",
    },
  },
  img: {
    width: "100px",
  },
  imgBody: {
    zIndex: "100",
    opacity: "1",
  },
  text: {
    fontSize: "14px",
    textAlign: "left",
    width: "220px",
  },
});

const Card = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imgBody}>
        <img src={props.img} className={classes.img} />
      </div>
      <div style={{marginLeft:'6px'}}>
        <center>
          <h4>{props.title}</h4>
          <p className={classes.text}>{props.body}</p>
        </center>
      </div>
    </div>
  );
};

export default Card;
