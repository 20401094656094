import React from "react";
import active_logo from '../images/activate_color.png';
import alpla_logo from '../images/alpla_square.png';
import dview_logo from '../images/D-View-color.png';
import hdi_logo from '../images/hdi_color.png';
import nvidia_logo from '../images/nvidia_color.png';
import prt_logo from "../images/prt_square.png"
import rekart_logo from '../images/rekart_color.png';
import saahas_logo from '../images/saahas_color.png';
import teckTorch_logo from '../images/tektorch_color.png';

const Client = () =>{

    return(
        <div>
            <center>
                <h1>Our Clients</h1>
                <div>
                    
                    <a href="https://www.alpla.com/" target="_blank"><img src={alpla_logo} style={{width:'120px',marginRight:"40px"}}/></a>
                    <a href="https://petrecyclingteam.com/en" target="_blank"><img src={prt_logo} style={{width:'120px',marginRight:"40px"}}/></a>
                    <a href="https://saahaszerowaste.com/" target="_blank"><img src={saahas_logo} style={{width:'100px', marginRight:"40px"}}/></a>
                    {/* <a href="http://www.rekart.co.in/" target="_blank"><img src={rekart_logo} style={{width:'120px',marginRight:"40px"}}/></a> */}
                    {/* <a href="https://hasirudalainnovations.com/" target="_blank"><img src={hdi_logo} style={{width:'150px'}}/></a> */}
                </div>
                
                <h1>Tech Partners</h1>
                <div>
                    <a href="https://www.nvidia.com/en-in/deep-learning-ai/startups/" target="_blank"><img src={nvidia_logo} style={{width:'150px', marginRight:"40px"}}/></a>
                    <a href="https://www.tektorch.ai/" target="_blank"><img src={teckTorch_logo} style={{width:'150px', marginRight:"40px"}}/></a>
                    <a href="https://dview.ai/" target="_blank"><img src={dview_logo} style={{width:'150px', marginRight:"40px"}}/></a>
                    <a href="https://aws.amazon.com/activate/" target="_blank"><img src={active_logo} style={{width:'150px', marginRight:"40px"}}/></a>
                </div>
            </center>
        </div>
    )
}

export default Client;