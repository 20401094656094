import { Background, Parallax } from "react-parallax";

import Button from "@material-ui/core/Button";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import video from "../images/vinglabs_proteus_1080p.mp4";

const useStyles = makeStyles({
  insideStyles: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "100",
  },

  inner: {
    height: "900px",
  },
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: "2",
    background:
      "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(209,209,209,0.72) 46%, rgba(219,219,219,0.75) 48%, rgba(230,230,230,1) 64%, rgba(255,255,255,1) 100%)",
    // background: "#021d21",
    // opacity: "0.3",
  },
});


const About = () => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <div>
      <Parallax strength={400}>
        <div className={classes.inner}>
          <div className={classes.overlay}></div>
          <div className={classes.insideStyles}>
            <center>
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe8qOQx9_DTK5st_p5wbMM090nFJ3ekJdjCwf98NsYlGVv-lw/viewform?embedded=true" width={matches?"300px":"640px"} height="900" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </center>
          </div>
        </div>
        <Background>
          <video autoPlay muted loop id="myVideo" className={classes.zoom}>
            <source src={video} type="video/mp4" />
          </video>
        </Background>
      </Parallax>
    </div>
  );
};

export default About;
