import { Background, Parallax } from "react-parallax";

import Cards from "../subComponents/whySection/card";
import Grid from "@material-ui/core/Grid";
import Hidden from '@material-ui/core/Hidden';
import React from "react";
import accuracyImg from "../images/vinglabs_accuracy_color_transparent.png";
import analyticsImg from "../images/vinglabs_analytics_color_transparent.png";
import automateImg from "../images/vinglabs_automate_color_transparent.png";
import { createMuiTheme } from "@material-ui/core/styles";
import designImg from "../images/vinglabs_design_color_transparent.png";
import healthImg from "../images/vinglabs_health_color_transparent.png";
import { makeStyles } from "@material-ui/core/styles";
import revenueImg from "../images/vinglabs_revenue_color_transparent.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import video from "../images/vinglabs_proteus_1080p.mp4";

const theme = createMuiTheme();

const useStyles = makeStyles({
  inner: {
    // height: "650px",
    // width: "100%",
  },
  why:{
    display:"flex",
    flexWrap:"wrap",
    justifyContent:"space-around",
    padding:"20px"
  },
  overlay: {
    // height: "100%",
    // width: "100%",
    // position: "absolute",
    // top: "0px",
    // left: "0px",
    // // zIndex: "2",
    // background: "#021d21",
    // opacity: "0.3",
  },
  overlay_mobile:{
    background:"#175a5d",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "0px",
    left: "0px",
  }
});

function WhySection() {
  const classes = useStyles();
  // const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery('(max-width:850px)');


  return (
    <div className={classes.root}>
      <center>
        <h1>Why Vinglabs?</h1>
      </center>   
      <Parallax strength={400}>
          <div className={isMobile ? classes.overlay_mobile : classes.overlay}></div>
          <div className={classes.why}>
                <Cards
                  img={automateImg}
                  title="Automate Work Flow"
                  body="NOESIS coupled with HERMES or PROTEUS can easily automate the process of segregation and recovering recyclables from the waste stream."
                />
                <Cards
                  img={accuracyImg}
                  title="Higher Accuracy"
                  body="NOESIS leverages cutting edge AI to classify recyclables with a high accuracy thereby minimizing error and improving bales purity."
                />
                <Cards
                  img={revenueImg}
                  title="Higher Revenue"
                  body="NOESIS ensures maximum recovery of recyclables thus extracting maximum revenue from waste."
                />
              <Cards
                  img={healthImg}
                  title="Health and Safety"
                  body="Automating the process of waste segregation prevents workers from working in extremely unhealthy and hazardous conditions."
                />
                {/* <Cards
                  img={analyticsImg}
                  title="Analytics and Visualisation"
                  body="NOESIS analytics and visualization platform provide live statistics and volume predictions of recovered recyclables thereby keeping operations smooth."
                />
                <Cards
                  img={designImg}
                  title="Modular Design"
                  body="NOESIS is easily installable in any existing conveyer based facility with little downtime."
                /> */}
          </div>
            {/* <Grid container spacing={8}>
              <Grid item sm={2} xs={2} md={2} >
              </Grid>
              <Grid item sm={4} xs={8} md={4} >
                <Cards
                  img={automateImg}
                  title="Automate Work Flow"
                  body="NOESIS coupled with HERMES or PROTEUS can easily automate the process of segregation and recovering recyclables from the waste stream."
                />
                <Cards
                  img={accuracyImg}
                  title="Higher Accuracy"
                  body="NOESIS leverages cutting edge AI to classify recyclables with a high accuracy thereby minimizing error and improving bales purity."
                />
                <Cards
                  img={revenueImg}
                  title="Higher Revenue"
                  body="NOESIS ensures maximum recovery of recyclables thus extracting maximum revenue from waste."
                />
              </Grid>
              <Hidden smUp>
                <Grid item xs={2}>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item xs={2}>
                </Grid>
              </Hidden>
              <Grid item sm={4} xs={8} md={4}>
                <Cards
                  img={healthImg}
                  title="Health and Safety"
                  body="Automating the process of waste segregation prevents workers from working in extremely unhealthy and hazardous conditions."
                />
                <Cards
                  img={analyticsImg}
                  title="Analytics and Visualisation"
                  body="NOESIS analytics and visualization platform provide live statistics and volume predictions of recovered recyclables thereby keeping operations smooth."
                />
                <Cards
                  img={designImg}
                  title="Modular Design"
                  body="NOESIS is easily installable in any existing conveyer based facility with little downtime."
                />
              </Grid>
              <Grid item sm={2} xs={2} md={2}>
              </Grid>
              
            </Grid> */}

            <div style={{padding:'20px'}}></div>
            {!isMobile && <Background>
                <video autoPlay muted loop id="myVideo2">
                    <source src={video} type="video/mp4" />
                </video>
            </Background>}
      </Parallax>
    </div>
  );
}

export default WhySection;
