import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import brain from '../images/brain.png'
import hermes from "../images/vinglabs_hermes_air_sorter2.jpg";
import { makeStyles } from "@material-ui/core/styles";
import proteusImg from "../images/vinglabs_proteus_delta.JPG";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import vinglabGif from "../images/vinglabs_noesis_2.gif";

const theme = createMuiTheme();
const useStyles = makeStyles({
  root: {
    // position: "relative"
    marginBottom:'50px'
  },
  header: {
    marginTop: "20px",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(10%,-50%)",
  },
  imgMain: {
    width: "90vw",
  },
  HeaderTextBody: {
    width: "60vw",
    fontSize:"1.3rem",
    fontFamily: "Segoe UI",
    marginBottom:"80px"
  },
  HeaderText: {
    marginBottom: "20px",
  },

  bodyText:{
    width:"40vw",
    fontSize:"1.15rem",
    textAlign:'center'
  },
  imgBody: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
});

const Product = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <center>
          <Typography variant="h3" className={classes.HeaderText}>
            Vinglabs NOESIS
          </Typography>
          <img src={!isMobile ? vinglabGif:brain } className={classes.imgMain} />
          <p className={classes.HeaderTextBody}>
            Vinglabs NOESIS is our proprietary AI technology, 
            trained on over a million images, and can instantly 
            recognize different recyclables like Plastics (HDPE, PP , PET), 
            metals, etc. in a waste stream.
          </p>
        </center>
      </div>

      {/* <Grid container justify="center">
        <Grid item xs={12} lg={6}>
          <center>
            <Typography variant="h4" className={classes.HeaderText}>
              Vinglabs PROTEUS
            </Typography>
            <img src={proteusImg} className={classes.imgBody} />
            <p className={classes.bodyText}>
              Vinglabs&nbsp;PROTEUS&nbsp;is our pick and place system, 
              with a capability to sort recyclables in multiple 
              categories at high speeds 24x7. 
              The system&nbsp;can be retrofitted to any existing 
              conveyor system.
            </p>
          </center>
        </Grid>

        <Grid item xs={12} lg={6}>
          <center>
            <Typography variant="h4" className={classes.HeaderText}>
              Vinglabs HERMES
            </Typography>
            <img src={hermes} className={classes.imgBody} />
            <p className={classes.bodyText}>
              Vinglabs&nbsp;HERMES&nbsp;is our air 
              sorter with the capability to sort recyclables moving
              at ultra-high speeds.The system&nbsp;can be retrofitted to any existing 
              conveyor system.             
            </p>
          </center>
        </Grid>
      </Grid> */}
    </div>
  );
};

export default Product;
