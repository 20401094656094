import React from "react";
import { Parallax, Background } from "react-parallax";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-scroll";

import video from "../images/vinglabs_proteus_1080p.mp4";
// import video from "../images/ezgif.com-video-to-gif.gif";

const theme = createMuiTheme();
theme.typography.h2 = {
  fontWeight: "bold",
  color: "white",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.5rem",
  },
};

theme.typography.h6 = {
  color: "white",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
};

const useStyles = makeStyles({
  root: {
    // position: "relative",
  },
  insideStyles: {
    // padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "100",
  },
  // zoom: {
  //   transform: "scale(0.9)",
  // },
  inner: {
    height: "750px",
    // width: "100%",
  },
  overlay: {
    height: "0",
    width: "100%",
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: "2",
    background: "#021d21",
    opacity: "0.3",
  },
  btn:{
    color:'white',
    borderColor:'white',
    marginTop:'20px'
  }
});

function Home() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Parallax strength={400}>
        <div className={classes.inner}>
          <div className={classes.overlay}></div>
          <div className={classes.insideStyles}>
            <center>
              <ThemeProvider theme={theme}>
                <Typography variant="h2">
                AI Based monitoring for Recycling and Waste industry
                </Typography>
                <Typography variant="h6">
                  Reinventing the Recycling supply chain
                </Typography>
              </ThemeProvider>
              <Link
                activeClass="active"
                to="demo"
                spy={true}
                offset={-70}
                smooth={true}
                className={classes.barElm}
                duration={500}
              >
                <Button variant="outlined" disableElevation className={classes.btn}>
                Demo
                </Button>
              </Link>
            </center>
          </div>
        </div>
        <Background>
          <video autoPlay muted loop id="myVideo" className={classes.zoom}>
            <source src={video} type="video/mp4" />
          </video>
        </Background>
      </Parallax>
    </div>
  );
}

export default Home;
