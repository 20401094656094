import React from 'react';

const footerStyle = {
  backgroundColor: '#175a5d',
  color: '#fff',
  padding: '20px 0',
  width: '100%',
  bottom: 0,
};

const footerContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '0 20px',
};

const companyNameStyle = {
  fontSize: '24px',
  marginBottom: '10px',
};

const contactDetailsStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const contactInfoStyle = {
  fontSize: '16px',
  margin: 0,
};

const Footer = () => {
  return (
    <div style={footerStyle}>
      <div style={footerContainerStyle}>
        <h3 style={companyNameStyle}>Vinglabs Technologies</h3>
        <div style={contactDetailsStyle}>
          <p style={contactInfoStyle}>Phone: +918700674743</p>
          <p style={contactInfoStyle}>Email: contact@vinglabs.com</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
