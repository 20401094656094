import { makeStyles, useTheme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-scroll";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import logo from "../images/wesite_logo_top_white.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    flexGrow: 2,
  },
  barElm: {
    marginLeft: "16px",
    fontSize: "15px",
    cursor: "pointer",
  },
  appbar: {
    backgroundColor: "#175a5d",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuItems = [
    {
      menuTitle: "Home",
      pageURL: "home",
      offset: -70,
    },
    {
      menuTitle: "Products",
      pageURL: "product",
      offset: -70,
    },
    {
      menuTitle: "Why Vinglabs",
      pageURL: "whySection",
      offset: -60,
    },
    {
      menuTitle: "Partners",
      pageURL: "client",
      offset: -70,
    },
    // {
    //   menuTitle: "Case Study",
    //   pageURL: "/",
    // },
    {
      menuTitle: "Contact",
      pageURL: "about",
      offset: -70,
    },
  ];

  return (
    <div className={classes.root}>
      
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar>
          <img src={logo} width={!isMobile ? "170px" : "140px"} />
          <Typography variant="body2" className={classes.title}></Typography>
          {!isTablet ? (
            <>
              <Link
                activeClass="active"
                to="home"
                spy={true}
                offset={-70}
                smooth={true}
                className={classes.barElm}
                duration={500}
              >
                Home
              </Link>

              <Link
                activeClass="active"
                to="product"
                offset={-70}
                spy={true}
                smooth={true}
                className={classes.barElm}
                duration={500}
              >
                Products
              </Link>

              <Link
                activeClass="active"
                to="whySection"
                offset={-60}
                spy={true}
                smooth={true}
                className={classes.barElm}
                duration={500}
              >
                Why Vinglabs
              </Link>
              <Link
                activeClass="active"
                to="demo"
                spy={true}
                offset={-70}
                smooth={true}
                className={classes.barElm}
                duration={500}
              >
                Demo
              </Link>
              {/* <Link
                activeClass="active"
                to="client"
                spy={true}
                offset={-70}
                smooth={true}
                className={classes.barElm}
                duration={500}
              >
                Partners
              </Link> */}

              {/* <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                className={classes.barElm}
                duration={500}
              >
                Case Study
              </Link> */}

              <Link
                activeClass="active"
                to="about"
                spy={true}
                offset={-70}
                smooth={true}
                className={classes.barElm}
                duration={500}
              >
                Contact
              </Link>
            </>
          ) : (
            <>
              <IconButton
                // edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {menuItems.map((menuItem, index) => {
                  const { menuTitle, pageURL, offset } = menuItem;
                  return (
                    <MenuItem key={index + "ad21d"}>
                      <Link
                        activeClass="active"
                        to={pageURL}
                        spy={true}
                        offset={offset}
                        smooth={true}
                        className={classes.barElm}
                        duration={500}
                      >
                        {menuTitle}
                      </Link>
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </Toolbar>
        <a target="_blank" href="https://startling-licorice-cdaf89.netlify.app/" style={{"textDecoration":"none",color:"white"}}><div style={{"backgroundColor":"black","textAlign":"center"}}>
          <Typography>
            Vinglabs now has an installation at ALPLA(one of Europe's biggest recycler).Click to know more.
          </Typography>
        </div></a>
        
      </AppBar>
      
    </div>
  );
}
