import "./App.css";

import About from "./components/about";
import AppBar from "./components/navbar";
import Client from "./components/client";
import CssBaseline from "@material-ui/core/CssBaseline";
import Demo from "./components/demo";
import Footer from "./components/footer";
import Home from "./components/home";
import Product from "./components/product";
import React from "react";
import { Toolbar } from "@material-ui/core";
import WhySection from "./components/why";

function App() {
  return (
    <div>
      {/* <CssBaseline /> */}
      <AppBar />  

      <div id="home">
        <Toolbar /> 
        <Home />
      </div>
      <div id="product">
        <Product />
      </div>
      <div id="whySection">
        <WhySection />
      </div>
      <div id="demo">
        <Demo/>
      </div>
      <div id="client">
        {" "}
        <Client />
      </div>
      <div id="about">
        {" "}
        <About />
      </div>

      <Footer/>
    </div>
  );
}

export default App;
