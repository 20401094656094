import React from "react";

const Demo = () =>{
    // const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    console.log(window.innerWidth)

    return(
        <div>
            <center>
                <h1>Demo</h1>
                <iframe style={{width:"755px",height:"424px", maxWidth:"80%",maxHeight:"calc((100vw - 40px) / (16/9))"}} src="https://www.youtube.com/embed/FVIvcDZkQ44" 
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen></iframe>
            </center>
        </div>
    )
}

export default Demo;